import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
// import styles from "./ChatHost.module.scss";
import { connect } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ChatView from './ChatView';
import { addMessage } from '../../../store/reducers/chatSlice';
import {chatMessagesSelector, isChatOpenSelector} from "../../../store/selectors/chatSelectors";

interface IConnectedChatHostProps {
    readonly messages: any[]; //toDo add model
    isChatOpen: boolean;
    // readonly isLoading: boolean;
    readonly addMessage: typeof addMessage;
    // readonly deleteMessage: typeof deletePOIMessage;
    // readonly updateMessage: typeof updatePOIMessage;
    // readonly addMediaMessage: typeof addPOIMediaMessage;
}

interface IExternalChatHostProps {
    isLoading: boolean; // toDo move to connected props
    // readonly closeChat: () => void;
    // readonly poi: any;
}

interface IChatHostProps
    extends IConnectedChatHostProps,
        IExternalChatHostProps {}

class Chat extends React.Component<IChatHostProps, any> {
    render() {
        return (
            <ChatView
                messages={this.props.messages}
                isLoading={this.props.isLoading}
                closeChat={this.hideChat}
                addMessage={this.addMessage}
                addMediaMessage={this.addMediaMessage}
                deleteMessage={this.deleteMessage}
                updateMessage={this.updateMessage}
                isChatOpen={this.props.isChatOpen}
            />
        );
    }

    private hideChat() {
        console.log('hide chat');
    }
    private addMessage = (message: string) => {
        console.log('add message');
        this.props.addMessage({
            id: 'id',
            ver: 1,
            // readonly message_language: string;
            message_content: message,
            dislikes_count: 3,
            likes_count: 4,
            modified_by: 'Nataliia',
            created_at: new Date().toISOString(),
            messageMedia: [],
            user: {
                id: 'Nataliia',
                user_nickname: 'Nataliaa'
            },
            messageReactions: []
        });
    };

    private deleteMessage = (messageId: string) => {
       console.log('delete message');
    };

    private updateMessage = (messageId: string, content: string, ver: number) => {
        console.log('update message');
    };


    private addMediaMessage = (imgUrl: string) => {
     console.log('add media message')
    };
}

export default connect(
    (state: RootState) => ({
        messages: chatMessagesSelector(state),
        isChatOpen: isChatOpenSelector(state)
        // isLoading: ,
    }),
    {
        addMessage,
        // deleteMessage,
        // updateomment,
        // addMediaMessage,
    }
)(Chat);

