import { isUndefined } from "../../utils/runtimeUtils";

export abstract class QueryParams<T> {
    constructor(private readonly _params: T) {}

    protected get params(): T {
        return this._params;
    }

    abstract getRaw(): T;
}

type LegalRestQueryParamValue = string | number | boolean | null;

export interface IRawRestQueryParams {
    readonly [key: string]: LegalRestQueryParamValue;
}

export class RestQueryParams extends QueryParams<IRawRestQueryParams> {
    constructor(params: IRawRestQueryParams = {}) {
        super(params);
    }

    get empty(): boolean {
        return Object.keys(this.params).length === 0;
    }

    add(key: string, value: LegalRestQueryParamValue): RestQueryParams {
        const raw: any = this.getRaw();
        raw[key] = value;

        return new RestQueryParams(raw);
    }

    getRaw(): IRawRestQueryParams {
        return Object.assign({}, this.params);
    }

    hasKey(key: string): boolean {
        return !isUndefined(this.params[key]);
    }

    value<T extends LegalRestQueryParamValue = any>(key: string): T | null {
        return this.params[key] ? (this.params[key] as T) : null;
    }

    prepareQuery(): string {
        if (null === this.params || undefined === this.params) {
            return "";
        }
        let query: string[] = [];
        Object.keys(this.params).forEach(key => {
            const value = this.params[key];
            query.push(`${encodeURIComponent(key)}=${value ? value : ""}`);
        });

        return query.length > 0 ? `?${query.join("&")}` : "";
    }
}
