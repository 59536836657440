import React from 'react';
import styles from './styles.module.scss';

interface IChatHeaderProps {
  readonly closeChat: () => void;
}

interface IChatHeaderState {
  isMenuOpen: boolean;
}

class ChatHeader extends React.Component<IChatHeaderProps, IChatHeaderState> {
  constructor(props: IChatHeaderProps) {
    super(props);

    this.state = {
      isMenuOpen: false
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.chatHeader}>
          <p>Dr. n. med. Janina Kowalska, internist</p>
          <div className={styles.status}>
            <span className={styles.statusIcon} />
            <p className={styles.statusInfo}>Online</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatHeader;
