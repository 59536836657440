import React from "react";
// import * as Cookies from "js-cookie";
import styles from  "./styles.module.scss";
import AgoraVideoCall from "../../components/AgoraVideoCall";
import { AGORA_APP_ID } from "../../agora.config";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {fullScreenSelector} from "../../store/selectors/sagaSelectors";
import UpcomingCall from "../UpcomingCall";
import CallExpired from "../CallExpired";
import CallNotFound from "../CallNotFound";
import {changeOnlineConsultationStatus, getOnlineConsultation} from "../../store/reducers/onlineConsultation";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {
  agoraTokenSelector,
  onlineConsultationEndsAtSelector,
  onlineConsultationErrorSelector,
  onlineConsultationSelector,
  onlineConsultationStartsAtSelector
} from "../../store/selectors/onlineConsultationSelectors";
import { setVideoCallDetails } from "../../store/reducers/videoCallDetails";
import {
  consultationIdSelector,
  userRoleSelector,
  userSecretSelector,
  agoraChannelSelector
} from "../../store/selectors/videoCallDetailsSelectors";
import {confirmPresenceAPI} from "../../api/confirmPresence";
import Loader from "../Loader";

const queryString = require('query-string');

export enum AttendeeRole {
    DOCTOR = 'ROLE_DOCTOR',
    CLINIC = 'ROLE_CLINIC',
    PATIENT = 'ROLE_PATIENT'
}

export enum ConsultationStatus {
    FINISHED = 'finished',
    NOT_STARTED = 'not_started',
    STARTED = 'started',
    SCHEDULED = 'scheduled',
    CANCELLED = 'cancelled',
    DRAFT = 'draft'
}

interface IConnectedMeetingProps {
    readonly isFullScreenMode: boolean;
    readonly agoraChannel: string | null;
    readonly agoraToken: any;
    readonly onlineConsultationStartsAt: any;
    readonly onlineConsultationEndsAt: any;
    readonly error: string | null;
    readonly getOnlineConsultation: typeof getOnlineConsultation;
    readonly setVideoCallDetails: typeof setVideoCallDetails;
    readonly consultationId: string | null;
    readonly userSecret: string | null;
    readonly userRole: AttendeeRole | null;
    readonly onlineConsultation: any;
    readonly changeOnlineConsultationStatus: typeof changeOnlineConsultationStatus;
}

interface IExternalMeetingProps {}

interface IMeetingProps extends
    IConnectedMeetingProps,
    IExternalMeetingProps,
    RouteComponentProps {}

interface IMeetingState {
    minutesTillCall: number | null;
    participantsPresent: boolean;
    shouldConsultationStart: boolean;
}

class Meeting extends React.Component<IMeetingProps, IMeetingState> {
    private subscriptions: Subscription[] = [];
    private participantPresenceInterval: any = null;
    private consultationShouldStartInterval: any;
    private consultationInterval: any;
    private videoProfile: string;
    private transcode: string;
    private attendeeMode: string;
    private baseMode: string;
    private appId: string = AGORA_APP_ID;
    private uid: string | null;

    constructor(props: IMeetingProps) {
        super(props);
        this.videoProfile = "480p_4";
        this.transcode = "interop";
        this.attendeeMode = "video";
        this.baseMode = "avc";
        if (!this.appId) {
            return;
        }
        this.uid = null;

        this.state = {
            minutesTillCall: null,
            participantsPresent: false,
            shouldConsultationStart: false
        }
    }

    componentDidMount(): void {
        const searchParams = queryString.parse(this.props.location.search),
            channel = searchParams.channel,
            consultationId = searchParams.consultationId,
            secret = searchParams.s,
            role = searchParams.role;

        if(channel && consultationId && secret && role) {
            this.props.setVideoCallDetails(channel, consultationId, secret, role);
        }

        if (this.props.agoraChannel && this.props.consultationId && this.props.userSecret && this.props.userRole) {
            this.props.getOnlineConsultation(this.props.consultationId, this.props.userSecret);
            this.consultationInterval = setInterval(() => {
                if (this.props.consultationId && this.props.userSecret) {
                    this.props.getOnlineConsultation(this.props.consultationId, this.props.userSecret);
                }
            }, 10 * 1000);
            let startsAt = new Date(this.props.onlineConsultationStartsAt).getTime();
            let msDifference = startsAt - new Date().getTime();
            let minutes = Math.floor(msDifference/1000/60);
            this.setState({minutesTillCall: minutes});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IMeetingProps>,
        prevState: Readonly<IMeetingState>,
        snapshot?: any): void {
        if (this.props.onlineConsultationStartsAt !== prevProps.onlineConsultationStartsAt &&
            this.props.onlineConsultationStartsAt) {
            let startsAt = new Date(this.props.onlineConsultationStartsAt).getTime();
            let msDifference = startsAt - new Date().getTime();
            let minutes = Math.floor(msDifference/1000/60);
            this.setState({minutesTillCall: minutes});
        }

        if ((this.props.consultationId !== prevProps.consultationId && this.props.consultationId) &&
            (this.props.userSecret !== prevProps.userSecret && this.props.userSecret)) {
            this.props.getOnlineConsultation(this.props.consultationId, this.props.userSecret);

            if (!this.consultationInterval) {
                this.consultationInterval = setInterval(() => {
                    if (this.props.consultationId && this.props.userSecret) {
                        console.log('in set consultatinon interval');
                        this.props.getOnlineConsultation(this.props.consultationId, this.props.userSecret);
                    }
                }, 10 * 1000);
            }
        }

        if (this.props.onlineConsultation !== prevProps.onlineConsultation && this.props.onlineConsultation) {
            this.consultationShouldStartInterval = setInterval(() => {
                this.setState({
                    shouldConsultationStart: new Date().getTime() >= new Date(this.props.onlineConsultation.startsAt).getTime()
                })
            }, 1000);
        }

        if (this.state.shouldConsultationStart !== prevState.shouldConsultationStart && this.state.shouldConsultationStart) {
            if (this.props.userRole === AttendeeRole.PATIENT) {
                this.participantPresenceInterval = setInterval(() => {
                    this.checkParticipantPresence()
                }, 1000);
            }
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        if (this.participantPresenceInterval) clearInterval(this.participantPresenceInterval);
        if (this.consultationShouldStartInterval) clearInterval(this.consultationShouldStartInterval);
    }

    render() {
        return (
            <div className={`${styles.wrapper} ${styles.meeting}`}>
                <div className={`${styles.agMain} ${this.props.isFullScreenMode ? styles.fullScreen : ''}`}>
                    <div className={styles.agContainer}>
                        {this.renderConsultationView()}
                    </div>
                </div>
            </div>
        );
    }

    private renderConsultationView = () => {
        if (!this.props.consultationId) {
            return <CallNotFound />;
        }

        if (this.props.onlineConsultation &&
            (this.props.onlineConsultation.status === ConsultationStatus.FINISHED ||
            (new Date().getTime() > new Date(this.props.onlineConsultationEndsAt).getTime() &&
            this.props.onlineConsultation.status === ConsultationStatus.NOT_STARTED))) {
            return <CallExpired/>;
        }

        if (!this.state.shouldConsultationStart ||
            (this.props.userRole && this.props.userRole === AttendeeRole.PATIENT &&
            this.state.shouldConsultationStart &&
            !this.state.participantsPresent)) {
            return (
                <UpcomingCall consultationTime={this.props.onlineConsultationStartsAt}
                              attendeeRole={this.props.userRole}/>
            );
        } else if ((this.props.userRole && this.props.userRole !== AttendeeRole.PATIENT && this.state.shouldConsultationStart) ||
                    (this.props.userRole === AttendeeRole.PATIENT && this.state.participantsPresent)) {
            return (
                <AgoraVideoCall videoProfile={this.videoProfile}
                                channel={this.props.agoraChannel}
                                token={this.props.userSecret}
                                transcode={this.transcode}
                                attendeeMode={this.attendeeMode}
                                baseMode={this.baseMode}
                                appId={this.appId}
                                uid={this.uid}
                                attendeeRole={this.props.userRole}
                                consultationStartTime={this.props.onlineConsultationStartsAt}/>
            );
        }

        return <Loader showLoader={true}/>;
    };

    private checkParticipantPresence = () => {
        if (!this.props.userSecret) {
            return;
        }

        this.subscriptions.push(
            confirmPresenceAPI(this.props.userSecret, true).pipe(
                tap((response: any) => {
                    let participants = response.participantAvailability['hydra:member'];
                    let participantsPresent: boolean = false;
                    // let participantsPresent = participants.every((item: any) => !!(item.availabilityExpiresAt && item.availableFrom));

                    participants.filter((item: {[key: string]: any}) => item.participantRole === AttendeeRole.CLINIC)
                        .map((participant: any) => {
                        if (participant.availabilityExpiresAt === null && participant.availableFrom === null) {
                            return participantsPresent = false;
                        } else if (new Date().getTime() > new Date(participant.availabilityExpiresAt).getTime()) {
                            return participantsPresent = false;
                        } else {
                            return participantsPresent = true;
                        }
                    });
                    this.setState({participantsPresent: participantsPresent}, () => {
                        if (this.state.participantsPresent && this.props.onlineConsultation &&
                            (this.props.onlineConsultation.status === ConsultationStatus.NOT_STARTED ||
                            this.props.onlineConsultation.status === ConsultationStatus.SCHEDULED)) {
                            this.props.changeOnlineConsultationStatus(ConsultationStatus.STARTED);
                        }
                    });
                }),
                catchError((error: any) => {
                    return of(error);
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        isFullScreenMode: fullScreenSelector(state),
        agoraToken: agoraTokenSelector(state),
        onlineConsultationStartsAt: onlineConsultationStartsAtSelector(state),
        onlineConsultationEndsAt: onlineConsultationEndsAtSelector(state),
        error: onlineConsultationErrorSelector(state),
        agoraChannel: agoraChannelSelector(state),
        consultationId: consultationIdSelector(state),
        userSecret: userSecretSelector(state),
        userRole: userRoleSelector(state),
        onlineConsultation: onlineConsultationSelector(state)
    }),
    {
        getOnlineConsultation,
        setVideoCallDetails,
        changeOnlineConsultationStatus
    }
)(withRouter(Meeting));
