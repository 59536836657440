import React from 'react';
import styles from "./styles.module.scss";
import {AttendeeRole} from "../Meeting";
import Loader from "../Loader";

interface IUpcomingCallProps {
    consultationTime: string;
    attendeeRole: AttendeeRole | null;
}

interface IUpcomingCallState {}

class UpcomingCall extends React.Component<IUpcomingCallProps, IUpcomingCallState> {

    render() {
        if (!this.props.consultationTime) {
          return <Loader showLoader={true}/>
        }

        const consultationDate = new Date(this.props.consultationTime),
            consultationHours = consultationDate.getHours() < 10 ? `0${consultationDate.getHours()}` :
                consultationDate.getHours(),
            consultationMinutes = consultationDate.getMinutes() < 10 ? `0${consultationDate.getMinutes()}` :
                consultationDate.getMinutes();

        return (
            <div className={`${styles.host}`}>
                <div className={styles.header}>
                    <img src={require(`./../../assets/images/logo_sm.png`)} alt="logo" className={styles.logo}/>

                    <h1>Your consultation starts at <span>{consultationHours}:{consultationMinutes}</span></h1>
                    <p className={styles.timezone}>Timezone: Eastern European Time</p>
                </div>
                <div className={styles.information}>
                    {this.props.attendeeRole && this.props.attendeeRole === AttendeeRole.PATIENT ?
                        (<p className={styles.warning}>Wait until the specialist lets you in.</p>) :
                        (<p className={styles.warning}>Remember to click button "Start consultation" in order to join the consultation.</p>)
                    }
                </div>
            </div>
        );
    }
}

export default UpcomingCall;
