import React from 'react';
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {
    onlineConsultationEndsAtSelector,
    onlineConsultationStartsAtSelector
} from "../../../store/selectors/onlineConsultationSelectors";
import {userRoleSelector} from "../../../store/selectors/videoCallDetailsSelectors";
import {AttendeeRole} from "../../Meeting";

interface IConnectedCounterProps {
    readonly onlineConsultationStartsAt: any;
    readonly onlineConsultationEndsAt: any;
    readonly userRole: AttendeeRole | null;
}

interface ICounterProps extends IConnectedCounterProps {}

interface ICounterState {
    count: number;
    isConsultationExceeded: boolean;
}

class Counter extends React.Component<ICounterProps, ICounterState> {
    private countdownInterval: any;
    private countInterval: any;
    constructor(props: ICounterProps) {
        super(props);

        this.state = {
            count: 0,
            isConsultationExceeded: false
        };
    }

    componentDidMount() {
        if (this.props.onlineConsultationEndsAt) {
            this.setState({count: this.getConsultationDuration(this.props.onlineConsultationEndsAt, new Date().toISOString())}, () => {
                if (this.state.count >= 0) {
                    this.countdownInterval = setInterval(() => {
                        this.setState(() => ({
                            count: Math.floor(this.state.count) - 1
                        }))
                    }, 1000);
                }
            });
        }
    }

    componentDidUpdate(
        prevProps: ICounterProps,
        prevState: ICounterState
    ) {

        if ((this.props.onlineConsultationEndsAt !== prevProps.onlineConsultationEndsAt && this.props.onlineConsultationEndsAt)) {
            this.setState({count: this.getConsultationDuration(this.props.onlineConsultationEndsAt, new Date().toISOString())});
        }

        if (this.state.count !== prevState.count && this.state.count < 0 && prevState.count >= 0) {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
            }
            this.setState({isConsultationExceeded: true});

            if (this.props.userRole !== AttendeeRole.PATIENT) {
                this.countInterval = setInterval(() => {
                    this.setState(({count}) => ({
                        count: Math.abs(count) + 1
                    }))
                }, 1000)
            }
        }
    }

    componentWillUnmount() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }

        if (this.countInterval) {
            clearInterval(this.countInterval);
        }
    }

    render() {
        return(
            <React.Fragment>
                <span className={`${styles.counter} ${this.state.isConsultationExceeded ? styles.exceeded : ''}`}>
                    {this.secondsToHms(this.state.count)}
                </span>
            </React.Fragment>
        );
    }

    private secondsToHms(d: number) {
        if (d <= 0) {
            return "00:00:00";
        }

        d = Number(Math.abs(d));
        let h = Math.floor(d / 3600),
            m = Math.floor(d % 3600 / 60),
            s = Math.floor(d % 3600 % 60);

        let hDisplay = h >= 10 ? h : "0" + h,
            mDisplay = m >= 10 ? m : "0" + m,
            sDisplay = s >= 10 ? s : "0" + s;
        return `${hDisplay}:${mDisplay}:${sDisplay}`;
    }

    private getConsultationDuration(endsAt: string, currentDate: string): number {
        let duration = (new Date(endsAt).getTime()) - (new Date(currentDate).getTime());
        return duration / 1000;
    }
}

export default connect(
    (state: RootState) => ({
        onlineConsultationStartsAt: onlineConsultationStartsAtSelector(state),
        onlineConsultationEndsAt: onlineConsultationEndsAtSelector(state),
        userRole: userRoleSelector(state)
    }),
    {}
)(Counter);
