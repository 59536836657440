import React from 'react';
import styles from "./styles.module.scss";

interface ICallExpiredProps {}

class CallExpired extends React.Component<ICallExpiredProps> {

    render() {
        return (
            <div className={`${styles.host}`}>
                <img src={require(`./../../assets/images/logo_sm.png`)} alt="logo" className={styles.logo}/>
                <h1 className={styles.header}>The meeting is already over</h1>
                <p className={styles.info}>
                    In case you missed the meeting, please contact admin or visit our webpage in order to make new appointment.
                </p>
            </div>
        );
    }
}

export default CallExpired;
