import React from 'react';
import styles from "./styles.module.scss";
import ChatComponent from "./ChatComponent";

interface IChatProps {}

interface IChatState {
  isChatOpen: boolean;
}

class Chat extends React.Component<IChatProps, IChatState> {
  constructor(props: IChatProps) {
    super(props);

    this.state = {
      isChatOpen: false
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(
      prevProps: IChatProps,
      prevState: IChatState
  ) {
  }

  render() {
    return(
        <div className={`${styles.chatWrapper}`}>
          <div className={styles.chatContainer}>
            <ChatComponent isLoading={false}/>
          </div>
        </div>
    );
  }
}

export default Chat;