import {createSlice} from "redux-starter-kit";
import {AttendeeRole} from "../../components/Meeting";

export interface IVideoCallDetailsState {
    agoraChannel: string | null;
    consultationId: string | null;
    userSecret: string | null;
    userRole: AttendeeRole | null;
}

export interface ISetVideoCallDetails {
    readonly payload: {
        agoraChannel: string;
        consultationId: string;
        userSecret: string;
        userRole: AttendeeRole;
    };
}

const initialState: IVideoCallDetailsState = {
    agoraChannel: null,
    consultationId: null,
    userSecret: null,
    userRole: null
};

const VideoCallDetailsSlice = createSlice({
    slice: "videoCallDetails",
    initialState: initialState,
    reducers: {
        setVideoCallDetails: {
            reducer: (state: IVideoCallDetailsState, action: ISetVideoCallDetails) => {
                return {
                    agoraChannel: action.payload.agoraChannel,
                    consultationId: action.payload.consultationId,
                    userSecret: action.payload.userSecret,
                    userRole: action.payload.userRole
                }
            },
            prepare(
                agoraChannel: string,
                consultationId: string,
                userSecret: string,
                userRole: AttendeeRole
            ) {
                return {
                    payload: {
                        agoraChannel: agoraChannel,
                        consultationId: consultationId,
                        userSecret: userSecret,
                        userRole: userRole
                    }
                };
            }
        }
    }
});

export const {
    setVideoCallDetails
} = VideoCallDetailsSlice.actions;

export default VideoCallDetailsSlice.reducer;
