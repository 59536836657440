import { RestQueryParams } from "./queryParams";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { IAPIHandler } from "./api";
import { map } from "rxjs/operators";

export class RxJsApiHandler implements IAPIHandler<RestQueryParams> {
    constructor(
        private readonly url: string = "",
        private readonly defaultHeaders?: Object
    ) {}

    get<T = any>(
        path: string,
        params: RestQueryParams,
        headers?: Object
    ): Observable<T> {
        return ajax.getJSON<T>(
            this.prepareUrl(path, params),
            Object.assign({}, this.defaultHeaders, headers)
        );
    }

    post<T = any>(
        path: string,
        payload?: any,
        params?: RestQueryParams,
        headers?: Object
    ): Observable<T> {
        return ajax
            .post(
                this.prepareUrl(path, params),
                payload,
                Object.assign({}, this.defaultHeaders, headers)
            )
            .pipe(map(response => response.response as T));
    }

    put<T = any>(
        path: string,
        payload?: any,
        params?: RestQueryParams,
        headers?: Object
    ): Observable<T> {
        return ajax
            .put(
                this.prepareUrl(path, params),
                payload,
                Object.assign({}, this.defaultHeaders, headers)
            )
            .pipe(map(response => response.response as T));
    }

    delete<T = any>(
        path: string,
        params?: RestQueryParams,
        headers?: Object
    ): Observable<T> {
        return ajax
            .delete(
                this.prepareUrl(path, params),
                Object.assign({}, this.defaultHeaders, headers)
            )
            .pipe(map(response => response.response as T));
    }

    private prepareUrl(
        path: string,
        params: RestQueryParams = new RestQueryParams()
    ): string {
        return `${this.url}/${path}${params.prepareQuery()}`;
    }
}
