import React from 'react';
import styles from './styles.module.scss';
import { Picker } from 'emoji-mart';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ImagePicker } from 'react-file-picker';
import {IBaseChatProps} from '../index';
import ChatListItemDeleteModal from '../ChatListItem/ChatListItemDeleteModal';


interface IChatActionsProps extends WithTranslation, IBaseChatProps {}

interface IChatActionsState {
  isEmojiShown: boolean;
  messageInput: string;
  imgUrl: string | null;
  isDeleteModalOpen: boolean;
}

class ChatActions extends React.Component<
  IChatActionsProps,
  IChatActionsState
> {
  private emojiPicker: any;
  private divElement: any;

  constructor(props: IChatActionsProps) {
    super(props);

    this.state = {
      isEmojiShown: false,
      messageInput: "",
      imgUrl: null,
      isDeleteModalOpen: false
    };

    this.emojiPicker = React.createRef();
    this.divElement = React.createRef();
  }

  componentDidUpdate(
    prevProps: Readonly<IChatActionsProps>,
    prevState: Readonly<IChatActionsState>,
    snapshot?: any
  ): void {
    // if (this.props.currentEditedContent !== prevProps.currentEditedContent) {
    //   const content =
    //     this.props.currentEditedContent === null
    //       ? ""
    //       : this.props.currentEditedContent;
    //   this.setState({
    //     messageInput: content
    //   });
    //   this.divElement.current.innerText = content;
    // }

    // if (this.props.currentMessageId !== prevProps.currentMessageId) {
    //   const { t } = this.props;
    //   this.divElement.current.dataset.text = t("messages.responsePlaceholder");
    // }
  }

  componentDidMount(): void {
    this.divElement.current.addEventListener("input", this.onChange, false);
  }

  componentWillUnmount() {
    document.removeEventListener("input", this.onChange, false);
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div
          className={`${styles.interactionContainer} "interaction-container"`}
        >
          <button
            type="button"
            className={styles.toggleEmoji}
            onClick={() => this.showEmojis()}
            // disabled={!this.props.isUserAuthenticated}
          >
            {/*<span className="feather icon-smile"/>*/}
          </button>

          {this.state.isEmojiShown ? (
            <span
              className={styles.emojiPicker}
              ref={el => (this.emojiPicker = el)}
            >
              <Picker onSelect={this.addEmoji} style={{ width: "100%" }} />
            </span>
          ) : null}

          <div
            contentEditable={true}
            className={styles.editableDiv}
            ref={this.divElement}
            data-text="Type something..."
          />

          <ImagePicker
              extensions={["jpg", "jpeg", "png"]}
              dims={{
                minWidth: 100,
                maxWidth: 2500,
                minHeight: 100,
                maxHeight: 2000
              }}
              maxSize={1000}
              onChange={(base64: string) => this.handleImageUpload(base64)}
              onError={(errMsg: string) => this.handleError(errMsg)}
          >
            <button
                className={`${styles.addMediaBtn} ${styles.btnAddImage}`}
                // disabled={!this.props.isUserAuthenticated}
            />
          </ImagePicker>

          <div className={styles.actionBtnContainer}>
            {/*{this.renderActionButton()}*/}
            <button
                className={styles.btnSend}
                onClick={() => this.messageAction()}
            />

            {this.renderImagePreview()}
          </div>
        </div>

        {this.renderDeleteMessageModal()}
      </React.Fragment>
    );
  }

  private showEmojis = () => {
    this.setState(
      {
        isEmojiShown: true
      },
      () => document.addEventListener("click", this.closeEmojiMenu)
    );
  };

  private closeEmojiMenu = (e: any) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          isEmojiShown: false
        },
        () => document.removeEventListener("click", this.closeEmojiMenu)
      );
    }
  };

  private addEmoji = (e: any) => {
    let emoji = e.native;
    this.setState({
      messageInput: this.state.messageInput + emoji
    });

    this.divElement.current.innerText = this.state.messageInput + emoji;
  };

  private renderActionButton() {
    if (this.state.messageInput.length > 0) {
      return (
        <button
          className={styles.btnSend}
          onClick={() => this.messageAction()}
        />
      );
    }

    // if (isNotNullOrUndefined(this.props.useCamera)) {
    //   return (
    //     <button
    //       className={`${styles.addMediaBtn} ${styles.btnCamera}`}
    //       onClick={this.props.useCamera}
    //     />
    //   );
    // }

    return (
      <ImagePicker
        extensions={["jpg", "jpeg", "png"]}
        dims={{
          minWidth: 100,
          maxWidth: 2500,
          minHeight: 100,
          maxHeight: 2000
        }}
        maxSize={1000}
        onChange={(base64: string) => this.handleImageUpload(base64)}
        onError={(errMsg: string) => this.handleError(errMsg)}
      >
        <button
          className={`${styles.addMediaBtn} ${styles.btnAddImage}`}
          // disabled={!this.props.isUserAuthenticated}
        />
      </ImagePicker>
    );
  }

  private renderImagePreview() {
    if (this.state.imgUrl === null || this.state.imgUrl === undefined) {
      return null;
    }

    return (
      <div className={styles.imagePreview}>
        <img src={this.state.imgUrl as string} alt="" />
        <button
          className={styles.cancelImgUpload}
          onClick={() => this.cancelImgUpload()}
        />
        <button className={styles.sendImg} onClick={() => this.uploadImg()} />
      </div>
    );
  }

  private cancelImgUpload = () => {
    this.setState({
      imgUrl: null
    });
  };

  private uploadImg = () => {
    if (this.state.imgUrl === null) {
      return;
    }

    // if (this.props.currentMessageId) {
    //   this.props.addMessageMediaResponse(
    //     this.props.currentMessageId,
    //     this.state.imgUrl
    //   );
    // } else {
      this.props.addMediaMessage(this.state.imgUrl);
//    }

    this.setState({
      imgUrl: null
    });
  };

  private messageAction = () => {
    console.log('message action');
    // const { t } = this.props;
    //
    // if (
    //   this.props.currentMessageId === null &&
    //   this.props.currentMessageVersion === null
    // ) {
      this.props.addMessage(this.state.messageInput);
      this.setState({
        messageInput: ""
      });
      this.divElement.current.dataset.text = 'Type something...';
      this.divElement.current.innerText = "";
    // } else if (
    //   this.props.currentMessageId &&
    //   this.props.currentMessageVersion === null
    // ) {
    //   this.props.addMessageResponse(
    //     this.state.messageInput,
    //     this.props.currentMessageId
    //   );
    //   this.setState(
    //     {
    //       messageInput: ""
    //     },
    //     () => {
    //       this.divElement.current.dataset.text = t("messages.placeholder");
    //       this.divElement.current.innerText = this.state.messageInput;
    //     }
    //   );
    //   this.props.changeCurrentMessageId(null);
    // } else if (
    //   this.props.currentMessageId &&
    //   this.props.currentMessageVersion
    // ) {
    //   this.props.updateMessage(
    //     this.props.currentMessageId,
    //     this.state.messageInput,
    //     this.props.currentMessageVersion
    //   );
    //
    //   this.setState(
    //     {
    //       messageInput: ""
    //     },
    //     () => {
    //       this.divElement.current.dataset.text = t("messages.placeholder");
    //       this.divElement.current.innerText = this.state.messageInput;
    //     }
    //   );
    //
    //   this.props.changeCurrentMessageId(null);
    //   this.props.changeCurrentMessageVersion(null);
    // }
  };

  private onChange = () => {
    const { t } = this.props;
    // if (
    //   this.props.currentMessageId &&
    //   this.props.currentMessageVersion &&
    //   this.divElement.current.innerText.length === 0
    // ) {
      this.setState({
        isDeleteModalOpen: true,
        messageInput: this.divElement.current.innerText
      });

      this.divElement.current.dataset.text = t("Your message");
   // }

    if (this.divElement.current.innerText.length > 0) {
      this.setState({
        isDeleteModalOpen: false
      });
    }

    this.setState({
      messageInput: this.divElement.current.innerText
    });
  };

  private handleImageUpload = (base64: string) => {
    console.log('in handle image upload');
    this.setState({
      imgUrl: base64
    });
  };

  private handleError = (errorMessage: string) => {
    const uuid = require("uuid/v4");
    console.log(errorMessage);
    // return this.props.addAlert({
    //   message: errorMessage,
    //   id: uuid(),
    //   displayDate: Date.now()
    // });
  };

  private renderDeleteMessageModal() {
    if (!this.state.isDeleteModalOpen) {
      return null;
    }

    return (
      <ChatListItemDeleteModal
        deleteMessage={this.deleteMessage}
        closeModal={this.toggleDeleteModal}
      />
    );
  }

  private toggleDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen
    });
  };

  private deleteMessage = () => {
    console.log('deleteMessage');
    // if (this.props.currentMessageId === null) {
    //   return;
    // }
    //
    // this.props.deleteMessage(this.props.currentMessageId);

    this.setState({
      isDeleteModalOpen: false,
      messageInput: ""
    });
    // this.props.changeCurrentMessageId(null);
    // this.props.changeCurrentMessageVersion(null);
  };
}

export default withTranslation()(ChatActions);
