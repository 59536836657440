import React from 'react';
import AgoraRTC from 'agora-rtc-sdk';
import '../../assets/fonts/css/icons.css';
import styles from './styles.module.scss';
import DeviceSelect from "./DeviceSelect";
import ProgressBar from "./ProgressBar";

const USER_ID = Math.floor(Math.random() * 1000000001);

interface IConnectedSettingsProps {}

interface IExternalSettingsProps {}

interface ISettingsProps extends
    IConnectedSettingsProps,
    IExternalSettingsProps {}

interface ISettingsState {
    cameraDevices: any[];
    speakerDevices: any[];
    microphoneDevices: any[];
    selectedMicrophoneId: string;
    microphoneAudioLevel: number | undefined;
}

class Settings extends React.Component<ISettingsProps, ISettingsState> {

    constructor(props: ISettingsProps) {
        super(props);

        this.state = {
            cameraDevices: [],
            speakerDevices: [],
            microphoneDevices: [],
            selectedMicrophoneId: '',
            microphoneAudioLevel: 0
        }
    }

    localStream = AgoraRTC.createStream({
        streamID: USER_ID,
        audio: true,
        video: true,
        screen: false
    });

    componentDidMount() {
        this.initLocalStream();
        this.getMediaDevices();
    }

    componentWillUnmount(): void {
        this.handleExit();
    }

    render() {
        return (
            <div className={styles.settingsContainer}>
                <div id="agora_local" className={styles.agoraLocalStream} />

                <div className={styles.details}>
                    <div className={styles.header}>
                        <h3>Settings</h3>
                    </div>
                    <div className={styles.settingsItem}>
                        <span className={styles.label}>Choose camera</span>
                        <DeviceSelect data={this.state.cameraDevices} onSelect={this.onCameraSelect}/>
                    </div>

                    <div className={styles.settingsItem}>
                        <span className={styles.label}>Choose speakers</span>
                        <DeviceSelect data={this.state.speakerDevices}
                                      onSelect={this.onSpeakerSelect}
                                      isDisabled={true}/>
                    </div>

                    <div className={styles.settingsItem}>
                        <span className={styles.label}>Choose microphone</span>
                        <DeviceSelect data={this.state.microphoneDevices}
                                      onSelect={this.onMicrophoneSelect}/>
                    </div>

                    <div id="mic-test"/>
                    <span className={styles.audioTestLabel}>Say something to check your microphone settings</span>
                    <div className={styles.progressContainer}>
                        <span className={styles.audioLevelLabel}>poor</span>
                            <ProgressBar completed={this.state.microphoneAudioLevel}/>
                        <span className={styles.audioLevelLabel}>excellent</span>
                    </div>

                    <div className={styles.btnContainer}>
                        <button className={styles.btn}
                                onClick={this.startConsultation}>
                            Start video consultation
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    private initLocalStream = () => {
        let me = this;
        me.localStream.init(
            () => {
                console.log("getUserMedia successfully");
                me.localStream.play("agora_local");
            },
            (err) => {
                console.log("getUserMedia failed", err);
            }
        );
    };

    handleExit = () => {
        this.localStream && this.localStream.close();
    };

    private getMediaDevices() {
        // method should be called after the calling createStream successfully.
        AgoraRTC.getDevices((devices) => {
            let microphoneDevices = devices.filter((device) => {
                return device.kind === "audioinput";
            });
            let videoDevices = devices.filter((device) => {
                return device.kind === "videoinput";
            });
            let speakerDevices = devices.filter((device) => {
                return device.kind === "audiooutput";
            });

            this.setState({
                cameraDevices: videoDevices,
                speakerDevices: speakerDevices,
                microphoneDevices: microphoneDevices
            });
        });
    }

    private onCameraSelect = (event: any) => {
        const selectedCameraId = event.target.value;

        if(this.localStream) {
            this.localStream.switchDevice(
                "video",
                selectedCameraId,
                  () => console.log('Camera is changed'),
                  (error) => console.log('An error occurred while changing camera:', error)
            )
        }
    };

    // toDo change speakers, for now controls for changing speakers are disabled
    private onSpeakerSelect = (event: any) => {
        // const selectedSpeakerId = event.target.value;
        if(this.localStream) {
          // this.localStream.switchDevice(
          //   "audio",
          //   event.target.value,
          //   () => console.log('Speakers are changed'),
          //   (error) => console.log('An error occurred while changing speakers:', error)
          // )
        }
    };

    private onMicrophoneSelect = (event: any) => {
        const selectedMicrophoneId = event.target.value;
        this.setState({selectedMicrophoneId: selectedMicrophoneId});

        if(this.localStream) {
            this.localStream.switchDevice(
                "audio",
                selectedMicrophoneId,
                () => {
                    this.testMicrophone();
                    console.log('Microphone is changed')
                },
                (error) => console.log('An error occurred while changing microphone:', error)
            )
        }
    };

    private testMicrophone() {
        AgoraRTC.getDevices((devices) => {
            let stream = AgoraRTC.createStream({
                streamID: USER_ID,
                // Set audio to true if testing microphone
                audio: true,
                microphoneId: this.state.selectedMicrophoneId,
                // Set video to true if testing camera
                video: false,
                // cameraId: selectedCameraId,
                // screen: false
            });

            // Initialize the stream
            stream.init(() => {
                stream.play("mic-test");
                // Print the audio level every 1000 ms
                setInterval(() => {
                    if(stream.getAudioLevel()) {
                        this.setState({microphoneAudioLevel: stream.getAudioLevel()})
                    }
                    console.log(`Local Stream Audio Level ${stream.getAudioLevel()}`);
                }, 1000);
            })
        });
    }

    private startConsultation = () => {
        console.log('Start video consultation');
    }
}

export default Settings;
