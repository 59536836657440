import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store, {persistor} from "./store";
import { Switch, Route } from 'react-router-dom';
import {ConnectedRouter} from "connected-react-router";
import PanelHost from "./components/PanelHost";
import {PersistGate} from "redux-persist/integration/react";

const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App} exact key="home" />,
                <Route path="/dashboard/" component={PanelHost} key="dashboard" />
            </Switch>
        </ConnectedRouter>
      </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
