import { IAPIHandler } from "./api";
import { QueryParams, RestQueryParams } from "./queryParams";
import { Observable } from "rxjs";
import memoizee from "memoizee";
import { memoizeObservable } from "../../utils/observableUtils";

export class MemoizingAPIHandlerDecorator<
    ParamsType extends QueryParams<any> = RestQueryParams
    > implements IAPIHandler<ParamsType> {
    constructor(
        private readonly next: IAPIHandler<ParamsType>,
        private readonly options?: memoizee.Options<any>
    ) {
        this.get = memoizeObservable<any>(this.get, this.options);
        this.post = memoizeObservable<any>(this.post, this.options);
        this.put = memoizeObservable<any>(this.put, this.options);
        this.delete = memoizeObservable<any>(this.delete, this.options);
    }

    get = <T>(
        path: string,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> => {
        return this.next.get<T>(path, params, headers);
    };

    post = <T = any>(
        path: string,
        payload?: any,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> => {
        return this.next.post<T>(path, payload, params, headers);
    };

    put = <T = any>(
        path: string,
        payload?: any,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> => {
        return this.next.put<T>(path, payload, params, headers);
    };

    delete = <T = any>(
        path: string,
        params?: ParamsType,
        headers?: Object
    ): Observable<T> => {
        return this.next.delete<T>(path, params, headers);
    };
}