import React from 'react';
import styles from './styles.module.scss';
// import ChatListItemDeleteModal from './ChatListItemDeleteModal';
import ChatListItemContent from './ChatListItemContent';

export enum ChatType {
  MESSAGE = "message",
  RESPONSE = "response",
  UPDATE = "update"
}

export enum MessageType {
  RESPONSE = "response",
  MESSAGE = "message",
  TEXT = "text",
  IMAGE = "image"
}

interface IChatListItemProps {
  readonly message: any;
  readonly deleteMessage: (messageId: string) => void;
  readonly updateMessage: (
      messageId: string,
    content: string,
    ver: number
  ) => void;
  readonly type: MessageType;
}

interface IChatListItemState {
  isMessageMenuOpen: boolean;
  isReportMessageOpen: boolean;
  message: any;
  isModalOpened: boolean;
}

class ChatListItem extends React.Component<
  IChatListItemProps,
  IChatListItemState
> {
  private menuElt: any;
  constructor(props: IChatListItemProps) {
    super(props);

    this.state = {
      isMessageMenuOpen: false,
      isReportMessageOpen: false,
      message: this.props.message,
      isModalOpened: false
    };

    this.menuElt = React.createRef();
  }

  componentDidUpdate(
    prevProps: Readonly<IChatListItemProps>,
    prevState: Readonly<IChatListItemState>,
    snapshot?: any
  ): void {
    if (this.props.message !== prevProps.message) {
      this.setState({
        message: this.props.message
      });
    }
  }

  render() {
    const message = this.state.message,
      avatarImage = undefined, //toDo add avatar img
        messageType =
            message.messageMedia && message.messageMedia.length
          ? MessageType.IMAGE
          : MessageType.TEXT;

    return (
      <React.Fragment>
        <div className={`${styles.messageItem} ${this.props.type === MessageType.MESSAGE ? styles.message : ''}`}>
          <div className={styles.messageContainer}>
          {this.renderAvatar(
            avatarImage,
              message.user ? message.user.user_nickname : "",
            ChatType.MESSAGE
          )}
          <div className={styles.messageContent}>
            <div
              className={`${styles.messageDetails} ${
                messageType !== MessageType.IMAGE ? styles.text : ""
              }
              ${this.props.type === MessageType.MESSAGE ? styles.message : styles.response}`}
            >

              <ChatListItemContent
                message={message}
                contentType={ChatType.MESSAGE}
                messageType={this.props.type}
              />

              <div className={styles.messageDate}>
                {this.convertDate(message.created_at)}
              </div>
            </div>
          </div>

          {/*<button*/}
          {/*  className={styles.btnMenu}*/}
          {/*  onClick={() => this.openMessageMenu()}*/}
          {/*/>*/}
          {/*<div className={styles.messageMenu} ref={el => (this.menuElt = el)}>*/}
          {/*  {this.state.isMessageMenuOpen ? (*/}
          {/*    <ChatListItemMenu*/}
          {/*        message={message}*/}
          {/*      updateMessage={this.props.updateMessage}*/}
          {/*      openModal={this.toggleModal}*/}
          {/*    />*/}
          {/*  ) : null}*/}
          {/*</div>*/}


        </div>
        </div>
        {/*{this.state.isModalOpened ? (*/}
        {/*  <ChatListItemDeleteModal*/}
        {/*    deleteMessage={this.deleteMessage}*/}
        {/*    closeModal={this.toggleModal}*/}
        {/*  />*/}
        {/*) : null}*/}
      </React.Fragment>
    );
  }

  private renderAvatar(
    avatarImage: string | undefined,
    messageAuthor: string | undefined,
    type: ChatType
  ) {
    if (avatarImage && this.props.type === MessageType.RESPONSE) {
      return (
        <div
          className={`${styles.authorAvatar} ${styles.authorImage}
          ${type === ChatType.RESPONSE ? styles.response : ""}`}
        >
          <img src={avatarImage} alt="avatar" />
        </div>
      );
    }
    if (messageAuthor && this.props.type === MessageType.RESPONSE) {
      let authorName = messageAuthor.charAt(0);
      return (
        <div
          className={`${styles.authorAvatar} ${
            type === ChatType.RESPONSE ? styles.response : ""
          }`}
        >
          <span>{authorName}</span>
        </div>
      );
    }
  }

  private openMessageMenu = () => {
    this.setState(
      {
        isMessageMenuOpen: true
      },
      () => document.addEventListener("click", this.closeMessageMenu)
    );
  };

  private closeMessageMenu = (e: any) => {
    if (this.menuElt !== null) {
      this.setState(
        {
          isMessageMenuOpen: false
        },
        () => document.removeEventListener("click", this.closeMessageMenu)
      );
    }
  };


  private toggleModal = () => {
    this.setState({
      isModalOpened: !this.state.isModalOpened
    });
  };

  private deleteMessage = () => {
    this.props.deleteMessage(this.props.message.id);

    this.setState({
      isModalOpened: false
    });
  };

  private convertDate(value: Date): string {
    if (value === null || value === undefined) {
      return "";
    }

    const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      };

    return new Date(value).toLocaleString("en-GB", options);
  }
}

export default ChatListItem;
