import React from 'react';
import styles from './styles.module.scss';

interface IChatItemDeleteModalProps {
  readonly deleteMessage: () => void;
  readonly closeModal: () => void;
}

class ChatListItemDeleteModal extends React.Component<
  IChatItemDeleteModalProps
> {
  render() {
    return (
      <React.Fragment>
        <div className={styles.modalContainer}>
          <div className={styles.modalContent}>
            <p className={styles.title}>
              Do you want to delete a message
            </p>
            <div className={styles.deleteOptions}>
              <button
                className={styles.btnCancel}
                onClick={() => this.props.closeModal()}
              >
                No
              </button>

              <button
                className={styles.btnDelete}
                onClick={() => this.props.deleteMessage()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatListItemDeleteModal;
