import React from 'react';
import styles from './styles.module.scss';
import {ChatType, MessageType} from '../index';


interface IChatListItemMenuProps {
  readonly message: any;
  readonly contentType: ChatType;
  readonly messageType?: MessageType;
}

class ChatListItemContent extends React.Component<IChatListItemMenuProps> {
  render() {
    const messageType =
      this.props.message.messageMedia && this.props.message.messageMedia.length
        ? MessageType.IMAGE
        : MessageType.TEXT;

    let baseUrl;
    let sourceUrl;
    this.props.message.messageMedia.length
      ? (baseUrl = `${process.env.REACT_APP_EXPLORIA_MEDIA_API_URL}/${this.props.message.messageMedia[0].media_rel_path}`)
      : (baseUrl = "");
    this.props.message.messageMedia.length
      ? (sourceUrl = `${baseUrl}/${this.props.message.messageMedia[0].media_name}.512${this.props.message.messageMedia[0].media_ext}`)
      : (sourceUrl = "");

    return (
      <React.Fragment>
        {messageType === MessageType.IMAGE ? (
          <img src={sourceUrl} alt="" className={styles.chatImage} />
        ) : (
          <div
            className={`${
              this.props.contentType === ChatType.RESPONSE
                ? styles.responseMessage
                : styles.chatMessage
            }
            ${this.props.messageType === MessageType.MESSAGE ? styles.message : ''}`}
          >
            {this.props.message.message_content}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ChatListItemContent;
