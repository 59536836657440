import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import {IChatState} from "../reducers/chatSlice";

export const selectChat = (state: RootState): IChatState => {
    return state.chat;
};

export const chatMessagesSelector = createSelector(
    [selectChat],
    (state: IChatState) => state.messages
);

export const isChatOpenSelector = createSelector(
    [selectChat],
    (state: IChatState) => state.isChatOpen
);

export const showLoaderSelector = createSelector(
    [selectChat],
    (state: IChatState) => state.showLoader
);

export const chatErrorSelector = createSelector(
    [selectChat],
    (state: IChatState) => state.error
);
