import { createSlice } from "redux-starter-kit";

export interface IChatState {
  messages: {[key: string]: any}[] | [];
  isChatOpen: boolean;
  showLoader: boolean;
  error: string | null;
}

export interface IRetrieveMessages {
  readonly payload: {
    messages: {[key: string]: any}[];
  };
}

export interface IAddMessage {
  readonly payload: {
    message: {[key: string]: any};
  };
}

export interface IChangeIsChatOpen {
  readonly payload: {
    isChatOpen: boolean;
  }
}

export interface IChangeShowLoader {
  readonly payload: {
    showLoader: boolean;
  };
}

export interface IChangeChatError {
  readonly payload: {
    error: string
  };
}

const initialState: IChatState = {
  messages: [],
  isChatOpen: false,
  showLoader: false,
  error: null
};

const chatSlice = createSlice({
  slice: "saga",
  initialState: initialState,
  reducers: {
    retrieveMessages: {
      reducer: (state: IChatState, action: IRetrieveMessages) => {
        return {
          messages: action.payload.messages,
          isChatOpen: state.isChatOpen,
          showLoader: state.showLoader,
          error: state.error
        }
      },
      prepare(messages: {[key: string]: any}[]) {
        return {
          payload: { messages: messages }
        };
      }
    },
    addMessage: {
      reducer: (state: IChatState, action: IAddMessage) => {
        return {
          messages: [...state.messages, action.payload.message],
          isChatOpen: state.isChatOpen,
          showLoader: state.showLoader,
          error: state.error
        }
      },
      prepare(message: {[key: string]: any}) {
        return {
          payload: { message: message }
        };
      }
    },
    changeIsChatOpen: {
      reducer: (state: IChatState, action: IChangeIsChatOpen) => {
        return {
          messages: state.messages,
          isChatOpen: action.payload.isChatOpen,
          showLoader: state.showLoader,
          error: state.error        };
      },
      prepare(isChatOpen: boolean) {
        return {
          payload: { isChatOpen: isChatOpen }
        };
      }
    },
    changeShowLoader: {
      reducer: (state: IChatState, action: IChangeShowLoader) => {
        return {
          messages: state.messages,
          isChatOpen: state.isChatOpen,
          showLoader: action.payload.showLoader,
          error: state.error        };
      },
      prepare(showLoader: boolean) {
        return {
          payload: { showLoader: showLoader }
        };
      }
    },
    changeFullScreenMode: {
      reducer: (state: IChatState, action: IChangeChatError) => {
        return {
          messages: state.messages,
          isChatOpen: state.isChatOpen,
          showLoader: state.showLoader,
          error: action.payload.error
        };
      },
      prepare(error: string) {
        return {
          payload: { error: error }
        };
      }
    },
  }
});

export const {
  retrieveMessages,
  addMessage,
  changeIsChatOpen,
  changeShowLoader,
  changeFullScreenMode
} = chatSlice.actions;

export default chatSlice.reducer;
