import React, {RefObject} from 'react';
import styles from './styles.module.scss';
import {IBaseChatProps} from '../index';
import ChatListItem, {MessageType} from '../ChatListItem';
import ChatActions from '../ChatActions';

interface IChatListProps extends IBaseChatProps {
  messages: any[];
  isLoading: boolean;
}

interface IChatListState {
  messages: any[];
  isMenuOpen: boolean;
}

class ChatList extends React.Component<IChatListProps, IChatListState> {
  private readonly containerRef: RefObject<any>;

  constructor(props: IChatListProps) {
    super(props);

    this.state = {
      messages: this.props.messages,
      isMenuOpen: false
    };

    this.containerRef = React.createRef();
  }

  componentDidUpdate(
    prevProps: Readonly<IChatListProps>,
    prevState: Readonly<IChatListState>,
    snapshot?: any
  ): void {
    if (this.props.messages !== prevProps.messages) {
      this.setState({
        messages: this.props.messages
      });
    }

    if (this.props.messages.length > prevProps.messages.length) {
      if (this.containerRef.current) {
        this.containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.chatContainer}>
          {this.renderChatList()}

          {this.renderEmptyChatList()}

          <div ref={this.containerRef} />
        </div>

        <ChatActions
          addMediaMessage={this.props.addMediaMessage}
          addMessage={this.props.addMessage}
          updateMessage={this.props.updateMessage}
          deleteMessage={this.props.deleteMessage}
        />
      </React.Fragment>
    );
  }

  private renderEmptyChatList() {
    if (this.hasMessages || this.props.isLoading) {
      return null;
    }

    return (
      <div className={styles.emptyMessagesContainer}>
        <span className="feather icon-edit" />

        <p>
          There are no messages yet.
        </p>
      </div>
    );
  }

  private renderChatList() {
    if (!this.hasMessages || this.props.isLoading) {
      return null;
    }

    let messages = this.state.messages.sort(
      (a: any, b: any) => {
        return (
          (new Date(a.created_at) as any) - (new Date(b.created_at) as any)
        );
      }
    );

    return messages.map((message: any, index: number) => {
      return (
        <ChatListItem
          key={index}
          message={message}
          type={index % 2 ? MessageType.MESSAGE : MessageType.RESPONSE}
          // activeLanguage={this.props.activeLanguage}
          deleteMessage={this.props.deleteMessage}
          updateMessage={this.updateMessage}
        />
      );
    });
  }

  private get hasMessages(): boolean {
    return this.state.messages.length > 0;
  }

  updateMessage = (content: string, messageId: string, ver: number) => {
    // this.props.changeCurrentEditedContent(content);
    // this.props.changeCurrentMessageId(messageId);
    // this.props.changeCurrentMessageVersion(ver);
  };
}

export default ChatList;
