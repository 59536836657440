import React from 'react';
import styles from "./styles.module.scss";
import {Tooltip, Whisper } from 'rsuite';
import {RouteComponentProps, withRouter} from 'react-router-dom';

export enum NavigationItems {
    VIDEO = 'video',
    CHAT = 'chat',
    FILES = 'files',
    SETTINGS = 'settings',
    HELP = 'help',
}

export interface INavMenu {
    url: string;
    icon: string;
    title: string;
    isDisabled?: boolean;
    tooltipText?: string;
    onClick?: (event: any, item: any) => void;
}

interface INavigationProps extends RouteComponentProps {
    navMenu: INavMenu[];
}

interface INavigationState {
    activeStep: string;
}

class Navigation extends React.Component<INavigationProps, INavigationState> {
    constructor(props: INavigationProps) {
        super(props);

      this.state = {
        activeStep: NavigationItems.VIDEO,
      };
    }

    componentDidMount(): void {
        let path = this.props.location.pathname;
        let routePath = path.substr(path.lastIndexOf('/') + 1);
        this.setState({activeStep: routePath});
    }

    render() {
        return(
            <div className={styles.navigationWrapper}>
                <ul className={styles.navigation}>
                    {this.props.navMenu.map((item: any, index: number) => {
                        return(
                            <li className={styles.navigationItem} key={index}>
                                {this.renderNavItem(item)}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }

    private renderNavItem(item: INavMenu) {
        return(
            <button className={`${item.isDisabled ? styles.disabled : ''} 
                     ${this.state.activeStep === item.title ? styles.active : ''}`}
                     onClick={(e) => this.handleClick(e, item)}>
                         {item.tooltipText ?
                (<Whisper placement="top"
                          trigger="hover"
                          speaker={<Tooltip>{item.tooltipText}</Tooltip>}>
                    <span className={`${styles.itemIcon} ${styles[item.icon]}`}/>
                </Whisper>) :
                (<span className={`${styles.itemIcon} ${styles[item.icon]}`}/>)}
                <span className={styles.itemLabel}>{item.title}</span>
            </button>
        );
    }

    private handleClick = (e: any, item: INavMenu) => {
        if(!item.isDisabled && !item.onClick) {
             this.setState({activeStep: item.title});
             this.props.history.push(item.url);
        }

        if(!item.isDisabled && item.onClick) {
            this.setState({activeStep: item.title});
            return item.onClick(e, item);
        }
    };
}

export default withRouter(Navigation);
