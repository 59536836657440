import { combineReducers } from "redux";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import sagaSlice from  "./sagaSlice";
import onlineConsultationSlice from "./onlineConsultation";
import chatSlice from "./chatSlice";
import videoCallDetailsSlice from "./videoCallDetails";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: sagaSlice,
    onlineConsultation: onlineConsultationSlice,
    videoCallDetails: videoCallDetailsSlice,
    chat: chatSlice,
    router: connectRouter(history)
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
