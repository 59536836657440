import React from 'react';
import styles from '../CallExpired/styles.module.scss';

interface ICallNotFoundProps {}

class CallNotFound extends React.Component<ICallNotFoundProps> {

    render() {
        return (
            <div className={`${styles.host}`}>
                <img src={require(`./../../assets/images/logo_sm.png`)} alt="logo" className={styles.logo}/>
                <h1 className={styles.header}>You are not allowed to join this consultation.</h1>
                <p className={styles.info}>Check your URL link or contact your Clinic.</p>
            </div>
        );
    }
}

export default CallNotFound;
