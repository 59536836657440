import {
  Action,
  configureStore,
  getDefaultMiddleware
} from "redux-starter-kit";
import rootReducer, { RootState } from "./reducers";
import { ThunkAction } from "redux-thunk";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const defaultMiddleware = getDefaultMiddleware(({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  }
})),
  epicMiddleware = createEpicMiddleware();

defaultMiddleware.push(epicMiddleware);
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['videoCallDetails'],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  middleware: defaultMiddleware
});

epicMiddleware.run(rootEpic);

const hotModule = module as typeof module & { hot: any };
if (process.env.NODE_ENV === "development" && hotModule.hot) {
  hotModule.hot.accept("./reducers", () => {
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
