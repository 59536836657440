import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {clinicHunterAPI} from "./provider/clinicHunterAPI";

export function confirmPresenceAPI(
    accessSecret: string,
    available: boolean

): Observable<any> {
    let payload = {
        accessSecret: accessSecret,
        available: available
    };
    return clinicHunterAPI.post(
        `online_consultation_participants/set_availability`,
        payload,
        new RestQueryParams(),
        // {
        //     Authorization: `Bearer ${accessToken}`,
        // },
    );
}
