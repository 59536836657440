import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, switchMap, map} from "rxjs/operators";
import {of} from "rxjs";
import {PayloadAction} from "redux-starter-kit";
import {
    getOnlineConsultation,
    changeOnlineConsultation,
    changeShowLoader,
    IGetOnlineConsultation,
    changeOnlineConsultationError
} from "../reducers/onlineConsultation";
import {getOnlineConsultationAPI} from "../../api/getOnlineConsultation";

const changeOnlineConsultationEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getOnlineConsultation.type),
        switchMap((action: PayloadAction<IGetOnlineConsultation>): any => {
            return getOnlineConsultationAPI((action.payload as any).consultationId, (action.payload as any).secret).pipe(
                map((consultation: any) => {

                    return changeOnlineConsultation({
                        id: consultation.id,
                        agoraChannel: consultation.agoraChannel,
                        agoraToken: consultation.agoraToken,
                        cancelledBy: consultation.cancelledBy,
                        clinic: consultation.clinic,
                        createdBy: consultation.createdBy,
                        endsAt: consultation.endsAt,
                        initialDescription: consultation.initialDescription,
                        isAccepted: consultation.isAccepted,
                        notes: consultation.notes,
                        paidAt: consultation.paidAt,
                        participants: consultation.participants,
                        payments: consultation.payments,
                        startsAt: consultation.startsAt,
                        status: consultation.status,
                        treatmentType: consultation.treatmentType
                    })
                }),
                catchError((error: any) => {
                    const errorMessage = error.message ? error.message : "Something went wrong";
                    return of(
                        changeShowLoader(false),
                        changeOnlineConsultationError(errorMessage)
                        // addAlert({message: error.response ? error.response['hydra:description'] : 'Error occurred'})
                    )
                }))
        })
    );


const onlineConsultationEpic = combineEpics(
    changeOnlineConsultationEpic,
);

export default onlineConsultationEpic;
