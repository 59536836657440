import React, { useEffect } from 'react';
import {Prompt} from "react-router-dom";

const UnloadHandler = (props: any) => {
    const [isPromptDisplay, setIsPromptDisplay] = React.useState(false);

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
            window.removeEventListener('unload', handleUnload);
            handleUnload();
        }
    }, []);

    const alertUser = (e: any) => {
        e.preventDefault();
        e.returnValue = ''
    };

    const handleUnload = async () => {
        setIsPromptDisplay(true);
    };

    return (
        <Prompt
            when={isPromptDisplay}
            message={() => 'Are you sure you want to leave this page?'}
        />
    )
};

export default UnloadHandler;
