import React from 'react';
import './App.scss';
import {Switch, Redirect} from 'react-router-dom';

interface IAppProps {}

interface IAppState {}

class App extends React.Component<IAppProps, IAppState> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Redirect push to="/dashboard/video"/>
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
