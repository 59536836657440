import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import styles from './styles.module.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';

export interface IBaseChatProps {
  readonly addMediaMessage: (imgUrl: string) => void;
  readonly addMessage: (comment: string) => void;
  readonly updateMessage: (
      commentId: string,
      content: string,
      ver: number
  ) => void;
  deleteMessage: any;
}

interface IChatViewProps extends WithTranslation, IBaseChatProps {
  readonly closeChat: () => void;
  readonly messages: any[];
  readonly isLoading: boolean;
  isChatOpen: boolean;
}

interface IChatViewState {
  messages: any[];
  commentInput: string;
  isEmojiShown: boolean;
  isDeleteModalOpen: boolean;
  imgUrl: string | null;
  textareaHeight: number;
}

class ChatView extends React.Component<IChatViewProps, IChatViewState> {
  private emojiPicker: any;

  constructor(props: IChatViewProps) {
    super(props);

    this.state = {
      messages: this.props.messages,
      commentInput: "",
      isEmojiShown: false,
      isDeleteModalOpen: false,
      imgUrl: null,
      textareaHeight: 48
    };

    this.emojiPicker = React.createRef();
  }

  componentDidUpdate(
      prevProps: Readonly<IChatViewProps>,
      prevState: Readonly<IChatViewState>,
      snapshot?: any
  ): void {
    if (this.props.messages!== prevProps.messages) {
      this.setState({
        messages: this.props.messages
      });
    }
  }

  render() {
    return (
        <div className={`${styles.chatWrapper} ${!this.props.isChatOpen ? 'hidden' : ''}`}>
          <ChatHeader closeChat={this.props.closeChat} />

          <ChatList
              messages={this.props.messages}
              isLoading={this.props.isLoading}
              deleteMessage={this.props.deleteMessage}
              addMessage={this.props.addMessage}
              addMediaMessage={this.props.addMediaMessage}
              updateMessage={this.props.updateMessage}
          />

          {this.renderLoader()}
        </div>
    );
  }

  private renderLoader() {
    if (!this.props.isLoading) {
      return null;
    }
    return (
        <p>Loader</p>
        // <Loader type={LoaderType.Local} showLocalLoader={this.props.isLoading} />
    );
  }
}

export default withTranslation()(ChatView);