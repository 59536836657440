import React from 'react';
import styles from "./styles.module.scss";

interface IDeviceSelectProps {
    data: any;
    onSelect: any;
    isDisabled?: boolean;
}

interface IDeviceSelectState {
   items: any[];
}

class DeviceSelect extends React.Component<IDeviceSelectProps, IDeviceSelectState> {
    constructor(props: IDeviceSelectProps) {
        super(props);
        this.state = {
            items: this.props.data
        };
    }

    componentDidUpdate(
        prevProps: Readonly<IDeviceSelectProps>,
        prevState: Readonly<IDeviceSelectState>,
        snapshot?: any
    ): void {
        if(this.props.data !== prevProps.data) {
            this.setState({items: this.props.data});
        }
    }

    render() {
        const { items } = this.state;
        return (
            <select onChange={this.props.onSelect}
                    id="select_id"
                    disabled={this.props.isDisabled}
                    className={styles.select}>
                {items.map((item: any, index) => {
                    return(
                        <option key={index}
                                value={item.deviceId}
                                className={styles.option}>
                            {item.label}
                        </option>
                    )
                })}
            </select>
        );
    }
}

export default DeviceSelect;
