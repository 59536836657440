import {clinicHunterAPI} from "./provider/clinicHunterAPI";
import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";

export function getOnlineConsultationAPI(
    consultationId: string,
    secret: string): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
        'X-Online-Consultation-Secret': secret
    };
    // if (undefined !== credentials.authToken && credentials.authToken) {
    //     headers['Authorization'] = `Bearer ${credentials.authToken}`;
    // } else if (undefined !== credentials.secret) {
    //     headers['X-Online-Consultation-Secret'] = credentials.secret;
    // }

    return clinicHunterAPI.get(
        `online_consultation/${consultationId}`,
        new RestQueryParams(),
        headers
    );
}
