import React from 'react';
import styles from "./styles.module.scss";

interface IMaintenanceProps {}

interface IMaintenanceState {}

class MaintenanceHost extends React.Component<IMaintenanceProps, IMaintenanceState> {
    render() {
        return (
            <div className={`${styles.maintenanceWrapper}`}>
                <div className={styles.header}>
                    <h1>Under construction...</h1>
                </div>
            </div>
        );
    }
}

export default MaintenanceHost;
