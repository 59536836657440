import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import {IVideoCallDetailsState} from "../reducers/videoCallDetails";

export const selectVideoDetails = (state: RootState): IVideoCallDetailsState => {
    return state.videoCallDetails;
};

export const agoraChannelSelector = createSelector(
    [selectVideoDetails],
    (state: IVideoCallDetailsState) => state.agoraChannel
);

export const consultationIdSelector = createSelector(
    [selectVideoDetails],
    (state: IVideoCallDetailsState) => state.consultationId
);

export const userSecretSelector = createSelector(
    [selectVideoDetails],
    (state: IVideoCallDetailsState) => state.userSecret
);

export const userRoleSelector = createSelector(
    [selectVideoDetails],
    (state: IVideoCallDetailsState) => state.userRole
);
