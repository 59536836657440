import React from 'react';
import styles from './styles.module.scss';

interface IProgressBarProps {
    completed: any;
}

interface IProgressBarState {}

class ProgressBar extends React.Component<IProgressBarProps, IProgressBarState> {

    render() {
        const {completed} = this.props;
        const fillerStyles = {
            width: `${completed * 100}%`,
        };

        return (
            <div className={styles.container}>
                <div className={styles.filler} style={fillerStyles}/>
            </div>
        );
    }
}

export default ProgressBar;
